<template>
  <div :class="{
    'mx-10': !mobile,
    'mx-2': mobile
  }">
    <div id="mainToolbarParent" class="w-full">
      <div id="mainToolbar" :style="fontSm" class="rounded-b-xl px-[32px] mb-[20px] bg-white py-6 transition-all"
        style="z-index: 99999 !important">
        <v-row>
          <v-col class="flex items-center" cols="6">
            <LogoPruvoOnlyIcon class="mr-[24px]" />
            <v-avatar class="mr-[12px]">
              <img :src="user?.avatar" alt="Avatar do Usuário"
                onerror="this.src = 'https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1-300x300.png'" />
            </v-avatar>
            <div class="flex flex-col items-end">
              <p class="mb-0 mr-4 font-medium">{{ user?.name }}</p>
            </div>
          </v-col>
          <v-col class="flex justify-end items-center" cols="6">
            <div v-if="!mobile" class="flex justify-between items-center mx-[20px]">
              <v-btn :disabled="fontSize === fontZoomStore.min" class="!border-2" color="blue" icon="mdi-minus"
                size="x-small" variant="outlined" @click="fontZoomStore.setFontZoom('sub')" />
              <span class="font-bold mx-[12px]">aA</span>
              <v-btn :disabled="fontSize === fontZoomStore.max" class="!border-2" color="blue" icon="mdi-plus"
                size="x-small" variant="outlined" @click="fontZoomStore.setFontZoom('add')" />
            </div>
            <v-btn class="!border-2 text-none" color="blue" variant="outlined" @click="btnAction">
              <span :style="fontSm">Sair</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-row no-gutters>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-sheet class="py-[20px] px-[24px] md:mr-[20px] rounded-xl shadow-sheet">
              <v-main>
                <div :style="fontXl" class="mb-[16px] font-medium flex justify-between items-center">
                  <span>
                    {{ data.application?.name }}
                  </span>
                  <v-tooltip location="bottom" text="Visualizar parecer">
                    <template v-slot:activator="{ props }">
                      <button v-bind="props" class="px-[6px] border bg-white text-black shadow transition-all rounded"
                        style="z-index: 9999" v-if="providerConfig.isShowAiDocument"
                        @click.stop="openLink('https://drive.google.com/file/d/1dEs-u5R0MaJhqOPva-Qs5ZnBfTQu2wAS/view?usp=sharing')">
                        <v-icon icon="mdi-eye" size="14"></v-icon>
                      </button>
                    </template>
                  </v-tooltip>
                </div>
                <div>
                  <h2 :style="fontBase" class="font-bold">Instruções</h2>
                  <p :style="fontSm" v-html="data.application?.instructions"></p>
                </div>
              </v-main>
            </v-sheet>
          </v-col>
          <template v-for="subFormQuestions of data.questions" :key="subFormQuestions.subFormOrder">
            <v-col v-for="(question, index) of (collect(subFormQuestions.questions) as any)
              .sortBy('order')
              .all()" :id="question.id" :key="question.id" cols="12">
              <v-sheet class="py-[20px] px-[24px] md:mr-[20px] rounded-xl shadow-sheet">
                <v-main>
                  <div class="flex justify-between">
                    <div class="flex items-center">
                      <div class="mr-[12px]">
                        <v-chip :style="fontSm" class="!font-medium" color="blue" rounded variant="elevated">{{ index +
                          1 }}
                        </v-chip>
                      </div>
                      <div class="mr-[12px]">
                        <v-chip :style="fontSm" class="!font-medium" color="blue" rounded variant="elevated">{{
                          getQuestionType(question.type) }}
                        </v-chip>
                      </div>
                      <div>
                        <v-chip class="!font-medium" color="blue" rounded>
                          <span :style="fontSm">?</span>
                        </v-chip>
                      </div>
                    </div>
                    <div class="bg-[#1C86E3] text-white py-2 px-3 rounded-lg text-sm">
                      <div class="text-right text-xs">Nota</div>
                      <div class="text-right font-medium text-base">
                        {{ getQuestionFinalGrade(question)
                        }}<span class="tespanxt-xs opacity-70">/{{ question.grade }}</span>
                      </div>
                    </div>
                  </div>
                  <div :style="fontBase" class="mt-[12px] mb-[20px]" v-html="question.main_text"></div>
                  <template v-if="question.type === QuestionType.OBJECTIVE">
                    <ObjectiveAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.MATRIX">
                    <MatrixAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.TRUE_FALSE">
                    <TrueFalseAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.OPEN_ENDED">
                    <OpenEndedAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.MULTIPLE_CHOICE">
                    <MultipleChoiceAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.SORTING">
                    <SortingAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.FILL_IN_THE_BLANK">
                    <FillInTheBlankAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.CONNECT_THE_DOTS">
                    <ConnectTheDotsAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.ESSAY">
                    <EssayAlternatives :question="question" />
                  </template>

                  <div v-if="question.feedback" class="relative mt-8">
                    <div :class="{
                      'absolute left-6 -top-[12px] px-1 bg-white': !mobile,
                      'relative px-1 bg-white': mobile
                    }">
                      <span class="text-[#808080] text-xs font-normal not-italic">Correção</span>
                    </div>
                    <div
                      class="flex flex-col px-[10px] py-[10px] border-[#E8F3FC] items-start self-stretch rounded-[20px] border-2"
                      v-html="question.feedback"></div>
                  </div>
                </v-main>
              </v-sheet>
            </v-col>
          </template>
          <div class="pt-[20px] pb-[60px] w-full flex justify-end items-center mr-[28px]">
            <v-btn class="!border-2 text-none bg-white" color="blue" variant="outlined" @click="btnAction">
              <span :style="fontSm">Sair</span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
      <v-col v-if="!mobile" cols="3">
        <div id="questionsSidebarParent" class="w-full">
          <div id="questionsSidebar" class="bg-white rounded-xl p-5 h-[80vh] overflow-y-auto">
            <div v-if="data.application?.author" class="flex justify-between">
              <div class="flex items-center">
                <v-avatar :image="data.application?.author?.avatar"></v-avatar>
                <div :style="fontLg" class="ml-[12px] font-bold">
                  {{ data.application?.author?.name ?? data.application?.author?.email }}
                </div>
              </div>
              <div class="bg-[#1C86E3] text-white py-2 px-3 rounded-lg text-right text-xs">
                <div>Nota</div>
                <div class="font-medium text-base">
                  {{ data.userApplication?.correction?.final_grade
                  }}<span class="text-xs opacity-70">/{{ maxGrade }}</span>
                </div>
              </div>
            </div>
            <v-radio-group class="mt-3" v-model="activeQuestion">
              <template v-for="subFormQuestions of data.questions" :key="subFormQuestions.subFormOrder">
                <div v-for="question of (collect(subFormQuestions.questions) as any).sortBy('order')
                  .all()" :key="question.id" :class="{
                    'bg-[#F4F4F4]': activeQuestion === question.id,
                    'hover:bg-[#F4F4F4]': activeQuestion !== question.id
                  }" class="flex cursor-pointer items-center mb-[12px] rounded-lg py-[8px] px-2"
                  @click="goToQuestion(question.id)">
                  <CorrectQuestionIcon v-if="getQuestionFinalGrade(question) === question.grade" />
                  <PartialCorrectIcon v-else-if="
                    getQuestionFinalGrade(question) > 0 &&
                    getQuestionFinalGrade(question) < question.grade
                  " />
                  <WrongQuestionIcon v-else-if="
                    question.answers?.length > 0 || getQuestionFinalGrade(question) === 0
                  " />
                  <svg v-else class="mr-2" fill="none" height="20" viewBox="0 0 20 20" width="20"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect fill="#FAFDFE" height="19" rx="9.5" width="19" x="0.5" y="0.5" />
                    <rect height="19" rx="9.5" stroke="#333333" width="19" x="0.5" y="0.5" />
                  </svg>
                  <span :style="fontBase" class="font-medium">
                    {{ question.order }} - {{ StringUtil.htmlToText(question.main_text) }}...
                  </span>
                </div>
              </template>
            </v-radio-group>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts" setup>
import ConnectTheDotsAlternatives from '@/components/feedback/alternatives/ConnectTheDotsAlternatives.vue'
import FillInTheBlankAlternatives from '@/components/feedback/alternatives/FillInTheBlankAlternatives.vue'
import MatrixAlternatives from '@/components/feedback/alternatives/MatrixAlternatives.vue'
import MultipleChoiceAlternatives from '@/components/feedback/alternatives/MultipleChoiceAlternatives.vue'
import ObjectiveAlternatives from '@/components/feedback/alternatives/ObjectiveAlternatives.vue'
import OpenEndedAlternatives from '@/components/feedback/alternatives/OpenEndedAlternatives.vue'
import SortingAlternatives from '@/components/feedback/alternatives/SortingAlternatives.vue'
import TrueFalseAlternatives from '@/components/feedback/alternatives/TrueFalseAlternatives.vue'
import LogoPruvoOnlyIcon from '@/components/icons/LogoPruvoOnlyIcon.vue'
import StringUtil from '@/utils/string.util'
import { QuestionType } from '@/enums/question-type.enum'
import {
  calculateFormQuestionsGrade,
  getApplicationQuestions,
  getFormQuestionsFromOrganization
} from '@/services/application.service'
import { useFontZoomStore } from '@/stores/font'
import collect from 'collect.js'
import { computed, onMounted, onUnmounted, provide, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useAuth } from '@/composables/useAuth'
import EssayAlternatives from '@/components/feedback/alternatives/EssayAlternatives.vue'
import CorrectQuestionIcon from '@/components/icons/CorrectQuestionIcon.vue'
import PartialCorrectIcon from '@/components/icons/PartialCorrectIcon.vue'
import WrongQuestionIcon from '@/components/icons/WrongQuestionIcon.vue'
import { getQuestionType } from '@/services/question.service'
import { getProviderFromUser } from '@/services/provider.service'

const { mobile } = useDisplay()
const { user } = useAuth()
const fontZoomStore = useFontZoomStore()
const route = useRoute()
const router = useRouter()

const data = ref({} as any)
const formQuestions = ref([] as any)
const maxGrade = ref(0)
const providerConfig = reactive({
  isShowAiDocument: false
})
const activeQuestion = ref<string>('')

const fontSm = computed(() => useFontZoomStore().getFontSm)
const fontBase = computed(() => useFontZoomStore().getFontBase)
const fontLg = computed(() => useFontZoomStore().getFontLg)
const fontXl = computed(() => useFontZoomStore().getFontXl)
const fontSize = computed(() => fontZoomStore.getFontZoom)

onMounted(async () => {
  data.value = await getApplicationQuestions(route.params.applicationId as string, true)
  formQuestions.value = await getFormQuestionsFromOrganization(data.value?.application?.form_id)

  try {
    providerConfig.isShowAiDocument = (await getProviderFromUser())?.configs?.applications?.is_show_ai_document || false
  } catch (error) {
    console.error(error)
  }

  if (!data.value) {
    await router.push({ name: 'dashboard' })
  }

  maxGrade.value = await calculateFormQuestionsGrade(data.value?.application?.form_ref)

  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4
  })

  data.value.questions.forEach((subFormQuestions: any) => {
    subFormQuestions.questions.forEach((question: any) => {
      const el = document.getElementById(question.id)

      if (el) {
        observer?.observe(el)
      }
    })
  })
})

onUnmounted(() => {
  window.removeEventListener('scroll', () => handleScroll('mainToolbar', 'mainToolbarParent', 60))
  window.removeEventListener('scroll', () =>
    handleScroll('questionsSidebar', 'questionsSidebarParent', 60, 'mt-[108px]')
  )
})

const btnAction = () => {
  router.push({ name: 'dashboard' })
}

provide('formQuestions', formQuestions)

const getQuestionFinalGrade = (question: any) =>
  question?.final_grade ?? question?.partial_grade ?? 0

const handleIntersection = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      activeQuestion.value = entry.target?.id ?? ''
    }
  })
}

const goToQuestion = (questionId: string) => {
  activeQuestion.value = questionId
  const element = document.getElementById(questionId)
  if (element) {
    const offset = 160
    window.scrollTo({
      top: element.offsetTop - offset,
      behavior: 'smooth'
    })
  }
}

const handleScroll = (
  elementId: string,
  parentElementId: string,
  offset: number,
  additionalClass: string = ''
) => {
  const element = document.getElementById(elementId) as HTMLElement
  const parentElement = document.getElementById(parentElementId) as HTMLElement

  if (!element) return

  if (window.scrollY > offset) {
    element.classList.add('position-fixed')
    if (additionalClass) element.classList.add(additionalClass)
    element.style.width = `${parentElement.offsetWidth}px`
    return
  }

  element.classList.remove('position-fixed')
  if (additionalClass) element.classList.remove(additionalClass)
  element.style.width = ''
}

window.addEventListener('scroll', () => handleScroll('mainToolbar', 'mainToolbarParent', 60))
window.addEventListener('scroll', () =>
  handleScroll('questionsSidebar', 'questionsSidebarParent', 60, 'mt-[108px]')
)

const openLink = (url: string) => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.click()
}
</script>
