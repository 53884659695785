<template>
  <v-btn v-if="
    application?.status === ApplicationStatus.NOT_STARTED.toString() ||
    userApplication?.status === ApplicationStatus.NOT_STARTED.toString()
  " class="!bg-[#A4CFF4] w-full !border-2 !capitalize" color="blue" elevation="0" rounded="xl" variant="flat"
    @click="start(application)">{{ application?.is_google_forms ? 'Abrir Google Formulário' : 'Iniciar' }}
  </v-btn>
  <v-btn v-else-if="application?.status === ApplicationStatus.IN_PROGRESS.toString()"
    class="!bg-[#A4CFF4] w-full !border-2 !capitalize" color="blue" elevation="0" rounded="xl" variant="flat"
    @click="resume(application)">Retomar
  </v-btn>
  <template v-else-if="userApplication?.status === ApplicationStatus.PUBLISHED.toString()">
    <div :class="{
      'flex flex-col gap-y-1 md:flex-row md:gap-4 items-center': application.application_ref.is_reinforce_study,
    }">
      <v-btn :disabled="userApplication?.status !== ApplicationStatus.PUBLISHED.toString()" :class="{
        'w-full': !application.application_ref.is_reinforce_study,
      }" class="!border-2 !capitalize" color="blue" elevation="0" rounded="xl" variant="outlined"
        @click="goToFeedback(application.id)">
        Gabarito
      </v-btn>
      <v-btn v-if="application.application_ref.is_reinforce_study" class="!border-2 !capitalize" color="blue"
        elevation="0" rounded="xl" variant="outlined" @click="generateReinforce">
        Gerar reforço
      </v-btn>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { db } from '@/config/firebase'
import { ApplicationStatus } from '@/enums/application-status.enum'
import type { Application } from '@/models/application.model'
import { generateReinforceStudy } from '@/services/application.service'
import { useLoadingStore } from '@/stores/loading'
import { useSnackbarStore } from '@/stores/snakebar'
import { doc } from 'firebase/firestore'
import { computed, inject, type PropType } from 'vue'
import { useDocument } from 'vuefire'

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true
  }
})

const loadingStore = useLoadingStore()
const snackbarStore = useSnackbarStore()

const start = inject('start') as Function
const resume = inject('resume') as Function
const goToFeedback = inject('goToFeedback') as Function

const userApplicationSource = computed(() => {
  if (!props.application?.id || !props.application?.user_id) return
  return doc(db, 'users', props.application.user_id, 'applications', props.application.id)
})

const userApplication = useDocument(userApplicationSource)

const generateReinforce = async () => {
  try {
    loadingStore.startLoading()
    await generateReinforceStudy(props.application.application_id)
    snackbarStore.showSnackbar(['Reforço gerado com sucesso'], 'success')
  } finally {
    loadingStore.stopLoading()
  }
}
</script>
