import { db } from '@/config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { getUserData } from '@/services/user.service'

export const getProviderById = async (id: string) => {
  if (!id?.length) return null

  const ref = doc(db, 'providers', id)
  const snapshot = await getDoc(ref)

  if (!snapshot?.exists()) {
    return null
  }

  return snapshot.data() || null
}

export const getProviderByOrganizationId = async (organizationId: string) => {
  if (!organizationId?.length) return null

  const ref = doc(db, 'organizations', organizationId)
  const snapshot = await getDoc(ref)

  if (!snapshot?.exists()) {
    return null
  }

  const organization = snapshot.data()
  if (!organization?.provider_id) {
    return null
  }

  return getProviderById(organization.provider_id)
}

export const getProviderFromUser = async () => {
  const user = await getUserData()

  if (!user?.get('active_organization.id')) {
    return null
  }

  return await getProviderByOrganizationId(user.get('active_organization.id'))
}
