<script lang="ts" setup>
import BaseLoading from './components/base/BaseLoading.vue'
import DialogAlert from './components/dialog/DialogAlert.vue'
import { RouterView } from 'vue-router'
import ConfettiExplosion from 'vue-confetti-explosion'
import { useConfettiStore } from './stores/confetti'
import { computed, onMounted, ref } from 'vue'
import BaseSnackBar from './components/base/BaseSnackBar.vue'
import { useSnackbarStore } from './stores/snakebar'
import { useFavicon } from '@vueuse/core'
import { getActiveOrganizationData } from './services/organization.service'
import { getProviderById } from '@/services/provider.service'

const confettiStore = useConfettiStore()
const snackbarStore = useSnackbarStore()

const confettiValue = computed(() => confettiStore.confetti)
const screenHeight = ref(window.innerHeight)
const screenWidth = ref(window.innerWidth)

const favicon = useFavicon()

onMounted(async () => {
  const activeOrganizationData = await getActiveOrganizationData()
  const provider = await getProviderById(activeOrganizationData.provider_id)

  if (provider?.configs?.page_title?.length) {
    document.title = provider?.configs?.page_title
  }

  if (provider?.configs?.page_icon?.length) {
    favicon.value = provider?.configs?.page_icon
  }

  window.addEventListener('resize', () => {
    screenHeight.value = window.innerHeight
    screenWidth.value = window.innerWidth
  })

  window.addEventListener('offline', () => {
    snackbarStore.showSnackbar(['Ops! A conexão foi perdida, verifique a sua conexão.'], 'error')
  })

  window.addEventListener('online', () => {
    snackbarStore.showSnackbar(['A conexão com a internet foi restabelecida.'], 'success')
  })
})
</script>

<template>
  <v-app>
    <RouterView />
    <ConfettiExplosion
      v-if="confettiValue"
      :duration="3000"
      :force="0.5"
      :particleCount="175"
      :particleSize="10"
      :stageHeight="screenHeight"
      :stageWidth="screenWidth"
      class="!absolute !top-0 !left-[50%]"
    />
    <BaseLoading />
    <DialogAlert />
    <BaseSnackBar />
  </v-app>
</template>
