<template>
  <v-row>
    <v-col cols="12" md="3">
      <v-sheet class="px-[24px] py-[20px] rounded-xl mb-6">
        <ApplicationsCalendar @send:date="selectedDate = $event" />
        <div class="pt-[24px] text-lg font-bold">
          <div class="pt-[24px] text-lg font-bold">
            {{ days[selectedDate.getDay() as keyof typeof days] }},
            {{ months[selectedDate.getMonth() as keyof typeof months] }}
            {{ selectedDate.getDate() }}
          </div>
        </div>
        <div v-for="applicationFromDay of applicationsFromDay" :key="applicationFromDay.id" class="mt-[12px]">
          <div class="flex justify-between items-center p-[12px] font-medium"
            style="border: 2px solid #e8f3fc; border-radius: 12px">
            <v-tooltip :text="applicationFromDay.name" location="top">
              <template v-slot:activator="{ props }">
                <div v-bind="props">
                  {{ applicationFromDay.name }}
                </div>
              </template>
            </v-tooltip>
            <div>
              <v-tooltip location="top" text="Informações">
                <template v-slot:activator="{ props }">
                  <v-btn class="!border-none" color="blue" elevation="0" icon="mdi-information-outline" size="large"
                    title="" v-bind="props" variant="outlined" @click="startCard(applicationFromDay)"></v-btn>
                </template>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-sheet>

      <template v-if="[ProviderEnum.LT.toString(), ProviderEnum.SABE2ED.toString()].includes(getProviderId)">
        <v-spacer class="py-5 px-6 cursor-pointer flex items-center justify-between rounded-xl bg-B&W-99 mt-5"
          @click="router.push('/support-material')">
          <button
            class="flex items-center w-full justify-between text-black font-rubik font-medium text-base normal-case border-none mr-auto">
            <div class="flex items-center">
              <v-icon class="mr-2 text-[#1C86E3] text-3xl">mdi-folder-outline</v-icon>
              <span class="text-left">Material de apoio</span>
            </div>
            <v-icon class="ml-2 text-[#1C86E3] text-2xl">mdi-chevron-right</v-icon>
          </button>
        </v-spacer>
      </template>
    </v-col>
    <v-col class="md:space-y-5 mb-5" cols="12" md="9">
      <v-sheet class="rounded-xl py-5 px-6">
        <v-main class="space-y-6">
          <v-spacer class="flex items-center space-x-3">
            <ExamsOpenIcon />
            <span class="text-B&W-20 font-medium !text-[1rem] leading-6 tracking-[0.15px]">
              Avaliações em aberto
            </span>
            <v-chip color="blue" rounded size="small" variant="flat">{{ applicationsNotStarted?.length }}
            </v-chip>
          </v-spacer>
          <template v-if="isLoadingOpenApplications">
            <ApplicationSkeletonLoader />
          </template>
          <template v-else-if="applicationsNotStarted?.length">
            <ApplicationsList :applications="applicationsNotStarted" :is_not_started="true"
              @resume="resumeApplication($event)" @start="startCard($event)" />
          </template>
          <template v-else>
            <div class="flex justify-center items-center pt-[24px] flex-column">
              <div class="mb-[24px] font-bold">Você não tem avaliações em aberto!</div>
              <img :src="svgPath.student_no_exams" alt="Estudante sem avaliações encontradas" />
            </div>
          </template>
        </v-main>
      </v-sheet>
      <v-sheet class="rounded-xl py-5 px-6">
        <v-main class="space-y-6">
          <v-spacer class="flex items-center space-x-3">
            <ExamsFinishedIcon />
            <span class="text-B&W-20 font-medium !text-[1rem] leading-6 tracking-[0.15px]">Avaliações concluídas</span>
            <v-chip color="blue" rounded size="small" variant="flat">{{ userApplicationsFinished.length }}
            </v-chip>
          </v-spacer>
          <template v-if="isUserApplicationsLoading">
            <ApplicationSkeletonLoader />
          </template>
          <template v-else-if="paginatedApplicationsFinished?.length">
            <ApplicationsList :applications="paginatedApplicationsFinished" :is_not_started="false" />
            <div class="flex justify-end">
              <v-pagination v-if="!isUserApplicationsLoading && totalPages > 1" :length="totalPages!"
                :modelValue="currentPage" :total-visible="5" color="primary" variant="outlined"
                @update:modelValue="changePage"></v-pagination>
            </div>
          </template>
          <template v-else>
            <div class="flex justify-center items-center pt-[24px] flex-col">
              <div class="mb-[24px] font-bold">Não encontramos nenhuma avaliação</div>
              <img :src="svgPath.student_no_exams_done" alt="Estudante sem avaliações concluídas" />
            </div>
          </template>
        </v-main>
      </v-sheet>
    </v-col>
  </v-row>
  <v-row>
    <v-dialog v-model="dialog" width="420">
      <SelectedApplication :selectedApplication="selectedApplication" @closeDialog="dialog = false"
        @start="startApplication($event)" />
    </v-dialog>
  </v-row>
</template>

<script lang="ts" setup>
import ApplicationsList from './ApplicationsList.vue'
import ApplicationsCalendar from './ApplicationsCalendar.vue'
import ExamsOpenIcon from '../icons/ExamsOpenIcon.vue'
import { computed, onMounted, readonly, ref, watch } from 'vue'
import { days, months } from '@/utils/date'
import {
  getApplicationsFromDay,
  getUserApplications,
  getUserCoursesOnActiveOrganization,
  saveFormDataIntoUserApplication,
  startUserApplication
} from '@/services/application.service'
import type { Application } from '@/models/application.model'
import SelectedApplication from '../card/SelectedApplication.vue'
import { useRouter } from 'vue-router'
import moment from 'moment'
import { useLoadingStore } from '@/stores/loading'
import ApplicationSkeletonLoader from '@/components/dashboard/ApplicationSkeletonLoader.vue'
import { getActiveOrganizationData } from '@/services/organization.service'
import ProviderEnum from '@/enums/provider.enum'
import { getUserData } from '@/services/user.service'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { useCollection } from 'vuefire'
import { db } from '@/config/firebase'
import { ApplicationStatus } from '@/enums/application-status.enum'
import ExamsFinishedIcon from '@/components/icons/ExamsFinishedIcon.vue'

const loadingStore = useLoadingStore()

const applicationsNotStarted = ref([] as Application[])
const applicationsFromDay = ref([] as Application[])
const isLoadingOpenApplications = ref(false)
const isLoadingApplicationsFromDay = ref(false)
const userData = ref({} as any)
const getProviderId = ref('')
const currentPage = ref(1)
const itemsPerPage = 4

const userApplicationsFinishedSource = computed(() => {
  if (!userData.value?.id) return

  return query(
    collection(db, 'users', userData.value.id, 'applications'),
    where('status', 'in', [
      ApplicationStatus.ENDED,
      ApplicationStatus.CORRECTED,
      ApplicationStatus.PUBLISHED,
      ApplicationStatus.WAITING,
      ApplicationStatus.CANCELLED,
      ApplicationStatus.EXPIRED
    ]),
    orderBy('ended_at', 'desc')
  )
})

const userApplicationsFinished = useCollection(userApplicationsFinishedSource)

const isUserApplicationsLoading = computed(() => userApplicationsFinished?.pending?.value)

const paginatedApplicationsFinished = computed(() => {
  if (!userApplicationsFinished.value?.length) return []
  const startIndex = (currentPage.value - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  return userApplicationsFinished.value.slice(startIndex, endIndex)
})

const totalPages = computed(() => {
  return Math.ceil((userApplicationsFinished.value?.length || 0) / itemsPerPage)
})

const changePage = (page: number) => {
  currentPage.value = page
}

onMounted(async () => {
  try {
    isLoadingOpenApplications.value = true
    isLoadingApplicationsFromDay.value = true
      ;[userData.value] = await Promise.all([
        getUserData(),
        handleOpenApplications(),
        handleApplicationsFromDay(),
        getProvider()
      ])

    const queries = await getQueries()

    queries.forEach((query) => {
      onSnapshot(query, async () => {
        await Promise.all([handleOpenApplications(), handleApplicationsFromDay()])
      })
    })
  } catch (e) {
    console.log(e)
  } finally {
    isLoadingOpenApplications.value = false
    isLoadingApplicationsFromDay.value = false
  }
})

async function getProvider() {
  const organization = await getActiveOrganizationData()
  getProviderId.value = organization?.provider_id
}

async function handleOpenApplications() {
  try {
    applicationsNotStarted.value = await getUserApplications()
  } catch (e) {
    console.log(e)
  }
}

async function handleApplicationsFromDay() {
  try {
    applicationsFromDay.value = await getApplicationsFromDay(
      moment(selectedDate.value).format('YYYY-MM-DD')
    )
  } catch (e) {
    console.log(e)
  }
}

const selectedDate = ref(new Date())

watch(selectedDate, async (newDate) => {
  applicationsFromDay.value = await getApplicationsFromDay(moment(newDate).format('YYYY-MM-DD'))
})

const selectedApplication = ref({} as Application)
const dialog = ref(false)

const startCard = (application: Application) => {
  if (application?.is_google_forms) {
    window.open(application?.google_forms?.responderUri, '_blank')
    return
  }

  dialog.value = true
  selectedApplication.value = application
}

const router = useRouter()

const startApplication = async (application: Application) => {
  try {
    loadingStore.startLoading()
    const userApplicationId = await saveFormDataIntoUserApplication(application)
    await startUserApplication(userApplicationId)
    await router.push(`/resolution/${userApplicationId}`)
  } catch (e) {
    console.log(e)
  } finally {
    loadingStore.stopLoading()
  }
}

const resumeApplication = async (application: Application) => {
  await router.push(`/resolution/${application.id}`)
}

watch(dialog, (newDialog) => {
  if (!newDialog) {
    selectedApplication.value = {} as Application
  }
})

const svgPath = ref(
  readonly({
    student_no_exams: '/assets/svg/student_no_exams.svg',
    student_no_exams_done: '/assets/svg/student_confused.svg'
  })
)

async function getQueries() {
  const user = await getUserData()

  const { coursesId, schoolsId } = await getUserCoursesOnActiveOrganization()

  const organizationApplicationRef = collection(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string,
    'applications'
  )

  const organizationApplicationQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('is_apply_to_organization', '==', true)
  )

  const schoolsApplicationsQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('schools_id', 'array-contains-any', schoolsId),
    where('is_apply_to_organization', '==', false)
  )

  const coursesApplicationsQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('courses_id', 'array-contains-any', coursesId),
    where('is_apply_to_organization', '==', false)
  )

  const usersApplicationQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('users_id', 'array-contains-any', [user.id]),
    where('is_apply_to_organization', '==', false)
  )

  const userApplicationsQuery = query(
    collection(user.ref, 'applications'),
    where('organization_id', '==', localStorage.getItem('activeOrganization') as string)
  )

  return [
    organizationApplicationQuery,
    schoolsApplicationsQuery,
    coursesApplicationsQuery,
    usersApplicationQuery,
    userApplicationsQuery
  ]
}
</script>
